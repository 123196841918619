import React, { useState } from "react";
import "./App.css";

import title from "./title.png";
import buttonIcon from "./btn.svg";

function App() {
  const [username, setUsername] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [followed, setFollowed] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate username
    const usernamePattern = /^@/;
    if (!usernamePattern.test(username)) {
      setError("Username must begin with '@'.");
      return;
    }

    setError(""); // Clear error message if validation passes

    const formData = new FormData();
    formData.append("username", username);
    formData.append("walletAddress", walletAddress);

    // Send the form data to the PHP backend
    fetch("http://gaytardio.fun/backend.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const tweetText = `I am officially Gay & Retarded! 🏳️‍🌈

@Gaytardios

https://www.gaytardio.fun/ `;

        // Construct the Twitter URL with the pre-filled tweet
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          tweetText
        )}`;
        // Open Twitter in a new tab
        window.open(twitterUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div data-aos="fade-in" className="form-container">
        <img src={title} className="title" />
        <form className="form" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">X Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your X username"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="walletAddress">Solana Wallet Address:</label>
            <input
              type="text"
              id="walletAddress"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              placeholder="Enter your Solana wallet address"
              required
            />
          </div>
          <div className="input-group">
            <label className="hp">
              Have you followed{" "}
              <a
                className="hyperlinks"
                href="https://x.com/Gaytardios"
                target="_blank"
              >
                @Gaytardios
              </a>{" "}
              & <br />
              {" ( "}
              <a
                className="hyperlinks"
                href="https://x.com/Gaytardios/status/1831690857646584196"
                target="_blank"
              >
                LIKE + RT THIS TWEET
              </a>
              {" )"} ?
            </label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="followed"
                  value="true"
                  checked={followed === true}
                  onChange={() => setFollowed(true)}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="followed"
                  value="false"
                  checked={followed === false}
                  onChange={() => setFollowed(false)}
                />
                No
              </label>
            </div>
          </div>
          {error && <p className="error-message">{error}</p>}
          <button disabled={!followed} type="submit" className="submit-btn">
            {followed ? <img src={buttonIcon} /> : "You must follow, like & RT"}
          </button>
        </form>
      </div>
    </>
  );
}

export default App;
